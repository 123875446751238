<template>
  <div id="SeatSelection">
    <top></top>
    <div class="Seat_body_div">
      <div class="Seat_body">
        <div class="Seat_input_body">
          <div class="Seat_input_div">
            <span>乘机人姓名：</span>
            <input
              type="text"
              v-model="name"
              placeholder="格式：张三@王五@李四"
            />
          </div>
          <div class="Seat_input_div">
            <span>乘机人票号：</span>
            <input
              type="text"
              v-model="piao"
              placeholder="格式：第一个乘机人票号"
            />
          </div>
          <div class="Seat_input_div">
            <span>选择座位：</span>
            <input type="text" v-model="zuo" placeholder="格式: 36A@36B@36C" />
          </div>
          <div class="Seat_input_div">
            <span>航班下标：</span>
            <input
              type="text"
              v-model="xiabiao"
              placeholder="格式: 从0以此类推"
            />
          </div>
          <div class="Seat_input_div">
            <span>南航账号：</span>
            <input type="text" v-model="admin" placeholder="南航下单账号" />
          </div>
          <div class="Seat_input_div">
            <span>南航密码：</span>
            <input type="text" v-model="pwd" placeholder="南航下单密码" />
          </div>
          <div class="Seat_input_div">
            <span>手机号码：</span>
            <input
              type="text"
              v-model="phone"
              placeholder="向南航传递的手机号码，11位数"
            />
          </div>
          <div class="Seat_input_div">
            <span>是否零扣： </span>
            <input type="text" v-model="ling" placeholder="1: 零扣" />
          </div>
        </div>
        <div class="Seat_xuanzuo">
          <button @click="xuanzuo">选座</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/BodyComponents.vue";
export default {
  name: "SeatSelection",
  data() {
    return {
      name: "",
      piao: "",
      zuo: "",
      xiabiao: "",
      admin: "",
      pwd: "",
      phone: "",
      ling: "",
    };
  },
  components: {
    top,
  },
  mounted() {},
  methods: {
    xuanzuo() {
      let arr = [
        this.name,
        this.piao,
        this.zuo,
        this.xiabiao,
        this.admin,
        this.pwd,
        this.phone,
      ];
      if (
        arr.includes("") ||
        (sessionStorage.getItem("nickname") ?? "") == ""
      ) {
        this.$message.error("信息填写不完整");
        return false;
      }
      this.$https(
        "selection/eyset/inao",
        "xing=" +
          this.name +
          "&piao=" +
          this.piao +
          "&zuo=" +
          this.zuo +
          "&xia=" +
          this.xiabiao +
          "&admin=" +
          this.admin +
          "&pwd=" +
          this.pwd +
          "&ling=" +
          this.ling +
          "&personnel=" +
          sessionStorage.getItem("nickname") +
          "&phone=" +
          this.phone,
        "post"
      ).then((res) => {});
    },
  },
};
</script>


<style scoped>
</style>